<script lang="ts" setup>
import type { HomeBanners } from "@/types";
const { t } = useT();

defineProps<{ banner: HomeBanners[number] }>();

const { open } = useAppModals();

const { playMysteryBoxAudio } = useMysteryBoxAudios();

const handleOpenMagicBox = () => {
	open("LazyOModalMysteryBox");
	playMysteryBoxAudio();

	dispatchGAEvent({
		event: "open_popup",
		location: "main_promotion",
		buttonName: "dailywheel"
	});
};

const PRIZE = {
	coins: 275000,
	entries: 500
};
</script>
<template>
	<MBanner
		:bg-img="banner.backgroundImage"
		:bg-img2x="banner.backgroundImage2x"
		:image="banner.image"
		:image-mobile="banner.imageMobile"
		:bg-color="banner.background"
		:second-image="banner.secondImage"
		:second-image-mobile="banner.secondImageMobile"
		:type="banner.type"
		@click="handleOpenMagicBox"
	>
		<template #default>
			<AText class="title" variant="takoradi" :modifiers="['medium']">
				<span v-if="banner.title" v-html="banner.title" />
			</AText>
		</template>
		<template #description>
			<div class="info">
				<AText variant="tlaquepaque" :modifiers="['bold']" class="money text-tlalnepantla">
					{{ t("GET") }} <span class="text-coyoacan">{{ numberFormat(PRIZE.coins) }}</span>
					{{ t("FUN COINS") }}
				</AText>
				<AText variant="tlaquepaque" :modifiers="['bold', 'uppercase']" class="money text-tlalnepantla">
					<span class="text-сirebon">+ {{ numberFormat(PRIZE.entries) }}</span>
					{{ t("FREE CITY COINS!") }}
				</AText>
			</div>
		</template>
		<template #actions>
			<div class="actions">
				<AButton variant="primary" size="xl" class="button">
					<AText variant="tuzla" :modifiers="['semibold']" class="text-tlalnepantla">
						{{ banner.buttonText }}
					</AText>
				</AButton>
			</div>
		</template>
	</MBanner>
</template>

<style lang="scss" scoped>
.value-decimal {
	position: relative;
	font-size: 14px;
	vertical-align: super;
	top: 0;
	left: 2px;

	@include media-breakpoint-down(md) {
		font-size: 11px;
	}
}
:deep {
	.app-banner {
		&__content {
			width: 100%;
			justify-content: center;
			align-items: flex-start;
			padding: gutter(4);

			@include media-breakpoint-down(md) {
				padding: gutter(3) gutter(2);
			}
		}
		&__desc {
			margin-top: gutter(0.5);
		}
	}
}

.title {
	line-height: 28px;
}

.info {
	display: flex;
	flex-direction: column;
	gap: gutter(0.5);
	color: v-bind(textColor);
	text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

	@include media-breakpoint-down(md) {
		gap: 0;
	}
}

.money {
	display: flex;
	align-items: center;
	gap: gutter(1);
	height: 38px;

	&:first-of-type {
		margin-bottom: gutter(1);

		@include media-breakpoint-down(md) {
			margin-bottom: 0;
		}
	}

	@include media-breakpoint-down(md) {
		margin-top: 0;
		height: 33px;
		justify-content: center;
	}

	@media (max-width: 360px) {
		font-size: 20px;
	}
}

.actions {
	width: 280px;
	display: flex;
	flex-direction: column;
	margin-top: 15px;
	align-items: center;

	@include media-breakpoint-down(md) {
		width: auto;
		margin-top: 0;
	}

	.button {
		width: 100%;

		@include media-breakpoint-down(md) {
			height: 40px;
			border-radius: 8px;
			margin-top: 0;
		}
	}
}

.price {
	display: flex;
	align-items: center;
	color: v-bind(textColor);
}
</style>
